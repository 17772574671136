import React, { useEffect, useRef, useState } from "react";

import './override.scss'

// Libraries
import { Row, Col, Container, Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { m } from "framer-motion";
import { Link as ScrollTo, scroller } from "react-scroll";


// Components
import Header, { HeaderNav, Menu } from "../../Components/Header/Header";
import Buttons from "../../Components/Button/Buttons";
import { fadeIn } from "../../Functions/GlobalAnimations";

import FooterStyle09 from "../../Components/Footers/FooterStyle09";


// Form
// Libraries
import { AnimatePresence } from "framer-motion";
import { Formik, Form } from "formik";

// Component
import { Checkbox, Input, TextArea } from "../../Components/Form/Form";
import { ContactFormStyle03Schema } from "../../Components/Form/FormSchema";
import MessageBox from "../../Components/MessageBox/MessageBox";
import Overlap from "../../Components/Overlap/Overlap";

import { resetForm, sendEmail } from "../../Functions/Utilities";

// Components
import Clients from '../../Components/Clients/Clients'

// Data
import {
  ClientData02,
} from "../../Components/Clients/ClientsData";

const FencingPage = (props) => {
  const form = useRef(null);
  const location = useLocation();

  useEffect(() => {
    let section = location.hash.replace("#", "").trim();
    console.log("Section: ", section);
    scroller.scrollTo(section, {
      href: "#",
      to: section,
      offset: 0,
      delay: 0,
      spy: true,
      smooth: true,
      duration: 800,
    });
  }, [location.hash]);

  return (
    <div id="homePage" style={props.style}>
      {/* Header start */}
      <Header
        className="header-with-topbar decor-header"
        topSpace={{ desktop: true }}
        type="responsive-sticky"
        id="menu"
      >
        {/* Header Start */}
        <HeaderNav
          fluid="fluid"
          theme="light"
          bg="white"
          expand="lg"
          containerClass="sm:!px-0"
          className="py-[0px] sm:py-[5px] px-[35px] md:pr-[15px] md:pl-0"
        >
          <Col className="col-auto mr-auto ps-lg-0">
            <Link
              aria-label="header logo"
              className="flex items-center -mr-[35px]"
              to="/"
            >
              <Navbar.Brand className="inline-block p-0 m-0">
                <img
                  className="default-logo"
                  width="125"
                  height="46"
                  loading="lazy"
                  src="/assets/img/logo.svg"
                  data-rjs="/assets/img/webp/logo-tussock@2x.webp"
                  alt="logo"
                />
                <img
                  className="alt-logo"
                  width="125"
                  height="36"
                  loading="lazy"
                  src="/assets/img/logo.svg"
                  data-rjs="/assets/img/webp/logo-tussock@2x.webp"
                  alt="logo"
                />
                <img
                  className="mobile-logo"
                  width="111"
                  height="36"
                  loading="lazy"
                  src="/assets/img/logo.svg"
                  data-rjs="/assets/img/webp/logo-tussock@2x.webp"
                  alt="logo"
                />
              </Navbar.Brand>
            </Link>
          </Col>
          <Navbar.Toggle
            id="mobile-menu"
            className="order-last md:ml-[25px] sm:ml-[17px]"
          >
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
          </Navbar.Toggle>
          <Navbar.Collapse
            id="mobile-menu-expandable"
            className="col-auto justify-center"
          >
            <Menu {...props} />
          </Navbar.Collapse>
          <Col className="col-auto md:hidden sm:hidden text-right !pr-0">
            <Buttons
              to="tel:2108571317"
              ariaLabel="button link"
              className="btn-fill btn-custom rounded-none font-medium font-serif uppercase md:mb-[15px] mr-[10px]"
              themeColor="#C9893F"
              color="#fff"
              iconPosition="before"
              icon="feather-phone-call"
              size="md"
              title="(210) 857-1317"
            />
            <ScrollTo
              href="#"
              to="contact"
              offset={0}
              delay={0}
              spy={true}
              smooth={true}
              duration={800}
              className="]"
            >
              <Buttons
                type="submit"
                className="btn-fill btn-custom rounded-none font-medium font-serif uppercase md:mb-[15px]"
                themeColor="#C9893F"
                color="#fff"
                size="md"
                title="Free Estimate"
              />
            </ScrollTo>
          </Col>
        </HeaderNav>
        {/* Header End */}
      </Header>
      {/* Header End */}

      {/* Section Start */}
      <section className="py-[40px] bg-[#f9f6f3] overflow-hidden cover-background sm:py-[80px]" style={{ backgroundImage: `url('/assets/img/webp/home-seo-agency-bg-01.webp')` }}>
        <Container>
          <Row className="items-center h-[720px] lg:h-[500px] sm:h-[550px] xs:h-[750px] overflow-hidden">
            <Col xl={5} md={6} className="sm:mb-[40px] overflow-hidden">
              <h2 className="heading-5 font-serif font-semibold text-[#374162] uppercase mb-[5px]">Discover the Art of:</h2>
              <h3 className="font-serif font-bold uppercase text-gradient bg-gradient-to-r from-[#C9893F] via-[#C9893F] to-[#d19f62] mb-[25px] tracking-[-1px]">Fence Transformation</h3>
              <p className="w-[90%] text-lg mb-[45px] leading-[30px] xs:text-xmd">Unlock the Beauty of Your Outdoor Space: Our Team of Skilled Craftsmen Specializes in <span className="font-bold text-[#C9893F]">Building</span>, <span className="font-bold text-[#C9893F]">Repairing</span>, and <span className="font-bold text-[#C9893F]">Painting</span> Exceptional Fences for Residential and Commercial Properties.</p>
          
          
              <Buttons ariaLabel="Link to Tel" to="tel:2108571317" className="mr-[38px] rounded-[4px] hidden sm:mr-[0px] sm:block font-medium font-serif uppercase md:mb-[15px]"  icon="feather-phone-call"
                      iconPosition="before" themeColor={["#C9893F", "#d19f62"]} size="xl" color="#fff"  title="(210) 857-1317" />
              <Buttons ariaLabel="Link to Contact Form" to="#contact" className="mr-[38px] rounded-[4px] sm:mr-[0px] sm:block font-medium font-serif uppercase md:mb-[15px]" themeColor={["#C9893F", "#d19f62"]} size="xl" color="#fff" title="Free Estimate Today" />
              {/* Modal Component Start */}
              {/* <CustomModal.Wrapper className="inline-block"
                modalBtn={<Buttons ariaLabel="link for modal button" type="submit" className="font-medium font-serif xs:!p-0 uppercase btn-link after:h-[2px] md:text-md md:mb-[15px] after:bg-[#374162] hover:text-darkgray" color="#374162" size="xl" title="See live demo" />} >
                <div className="w-[1020px] max-w-full relative rounded mx-auto">
                  <div className="fit-video">
                    <iframe width="100%" height="100%" className="shadow-[0_0_8px_rgba(0,0,0,0.06)]" controls src="https://www.youtube.com/embed/g0f_BRYJLJE?autoplay=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen ></iframe>
                  </div>
                </div>
              </CustomModal.Wrapper> */}
              {/* Modal Component End */}
            </Col>
            <Col xl={7} md={6} className="text-right">
              <img width="583px" height="608px" className="inline-block" src="/assets/img/Fence.png" alt="" />
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}

  {/* Section Start */}
      <section className="py-[110px] lg:py-[110px] md:py-[95px] sm:py-[80px] xs:py-[50px]">
        <Container>
          <Row>
            <Col className="mb-[6%]">
              <h5 className="font-serif text-gradient bg-gradient-to-r from-[#C9893F] via-[#C9893F] to-[#d19f62] text-center font-medium mb-[25px] sm:mb-[15px]">
              Transform Your Fence with Expert Paint Solutions
              </h5>
              <h6 className="font-serif text-darkgray text-center font-medium mb-[25px] sm:mb-[15px]">
              We've partnered with leading brands to ensure exceptional quality and stunning finishes.
              </h6>
            </Col>
          </Row>
          <Clients
            grid="row-cols-1 row-cols-md-2 row-cols-lg-4"
            theme="client-logo-style-03"
            data={ClientData02}
            animation={fadeIn}
          />
        </Container>
      </section>
  {/* Section end /*}
  

  {/* Section Start */}
      <section className="py-[130px] bg-[#f9f6f3] overflow-hidden lg:py-[90px] sm:py-[75px] xs:py-[50px]">
        <Container>
          <Row className="items-center justify-center">
            <Col lg={5} md={9} className="mb-[10px] md:mb-28">
              <span className="font-serif mb-[20px] text-gradient bg-gradient-to-r from-[#C9893F] via-[#C9893F] to-[#d19f62] inline-block uppercase font-medium tracking-[1px]">Unlocking the Full Potential of Your Property</span>
              <h4 className="font-serif font-semibold text-darkgray w-[95%]">Crafting Fences, Elevating Homes: Our Dedication</h4>
              <p className="w-[80%] mb-[25px] lg:w-[95%]">Discover the Difference Our Expertise Makes: Our Fence Specialists Combine Unwavering Commitment, Innovative Techniques.</p>
             
            </Col>
            <Col lg={7} md={9} xs={12} className="px-[55px] md:pr-[50px] md:pl-[5px]">
              <m.figure className="image-back-offset-shadow w-100">
                <m.img
                  className="rounded-[6px] w-auto h-auto"
                  src="/assets/img/fence2.png"
                  alt="our-services"
                  width="572.5"
                  height="436.53"
                  initial={{ opacity: 0, x: "0", y: "0" }}
                  whileInView={{ opacity: 1, x: "10px", y: "-20px" }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.4, easing: "linear" }}
                />
                <m.span
                  className="rounded-[6px]"
                  {...{ ...fadeIn, animate: { x: "35px", y: "5px" } }}
                ></m.span>
              </m.figure>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start Contact US */}
      <section
        id="contact"
        name="contact"
        className="py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px]"
        style={{
          backgroundImage: "url(/assets/img/webp/home-decor-bg-img-02.webp)",
        }}
      >
        <Container>
          <m.div {...fadeIn} transitionDelay={0.5}>
            <Overlap
              value={20}
              className="relative p-32 lg:p-[104px] md:p-[60px] sm:p-[55px] xs:px-[22px] xs:py-[44px] shadow-[0_0_30px_rgba(0,0,0,0.1)] bg-white"
            >
              <Row className="justify-center">
                <Col
                  xl={6}
                  lg={7}
                  className="col-12 text-center mb-[4.5rem] md:mb-12"
                >
                  <span className="font-serif mb-[5px] -tracking-[.5px] text-xmd block">
                    Fill out the form and we'll be in touch soon!
                  </span>
                  <h4 className="font-serif font-semibold text-darkgray">
                    How we can help you?
                  </h4>
                </Col>
                <Col className="col-12">
                  <Formik
                    initialValues={{
                      name: "",
                      email: "",
                      phone: "",
                      message: "",
                      terms_condition: false,
                    }}
                    validationSchema={ContactFormStyle03Schema}
                    onSubmit={async (values, actions) => {
                      actions.setSubmitting(true);
                      const response = await sendEmail(values);
                      response.status === 200 && resetForm(actions);
                    }}
                  >
                    {({ isSubmitting, status }) => (
                      <Form ref={form}>
                        <Row className="row-cols-1 row-cols-md-2">
                          <Col className="mb-16 sm:mb-[25px]">
                            <Input
                              showErrorMsg={false}
                              type="text"
                              name="name"
                              className="py-[15px] px-[20px] text-md w-full border-[1px] border-solid border-[#dfdfdf]"
                              labelClass="mb-[25px]"
                              placeholder="Your name"
                            />
                            <Input
                              showErrorMsg={false}
                              type="email"
                              name="email"
                              className="py-[15px] px-[20px] w-full text-md border-[1px] border-solid border-[#dfdfdf]"
                              labelClass="mb-[25px]"
                              placeholder="Your email address"
                            />
                            <Input
                              showErrorMsg={false}
                              type="tel"
                              name="phone"
                              className="py-[15px] px-[20px] w-full text-md border-[1px] border-solid border-[#dfdfdf]"
                              placeholder="Mobile number"
                            />
                          </Col>
                          <Col className="mb-16 sm:mb-[20px]">
                            <TextArea
                              className="border-[1px] border-solid border-[#dfdfdf] w-full py-[15px] px-[20px] text-md h-[210px] resize-none"
                              rows="6"
                              name="message"
                              placeholder="Your message"
                            ></TextArea>
                          </Col>
                          <Col className="text-left sm:mb-[20px]">
                            <Checkbox
                              type="checkbox"
                              name="terms_condition"
                              className="inline-block mt-[4px]"
                              labelClass="flex items-start"
                            >
                              <span className="ml-[10px] text-sm inline-block w-[85%]">
                                I accept the terms & conditions and I understand
                                that my data will be hold securely in accordance
                                with the
                                <Link
                                  aria-label="checkbox"
                                  to="/privacy"
                                  target="_blank"
                                  className="text-darkgray underline hover:text-fastblue"
                                >
                                  {" "}
                                  privacy policy
                                </Link>
                                .
                              </span>
                            </Checkbox>
                          </Col>
                          <Col className="text-right sm:text-center">
                            <Buttons
                              type="submit"
                              className={`btn-fill text-xs tracking-[1px] rounded-none py-[12px] px-[28px] uppercase${
                                isSubmitting ? " loading" : ""
                              }`}
                              themeColor="#C9893F"
                              size="md"
                              color="#fff"
                              title="Send Message"
                            />
                          </Col>
                        </Row>
                        <AnimatePresence>
                          {status && (
                            <Row>
                              <Col xs={12}>
                                <div
                                  initial={{ opacity: 0 }}
                                  animate={{ opacity: 1 }}
                                  exit={{ opacity: 0 }}
                                >
                                  <MessageBox
                                    className="mt-[20px] py-[10px]"
                                    theme="message-box01"
                                    variant="success"
                                    message="Thank you! We received your message and will be contacting your soon."
                                  />
                                </div>
                              </Col>
                            </Row>
                          )}
                        </AnimatePresence>
                      </Form>
                    )}
                  </Formik>
                </Col>
              </Row>
            </Overlap>
          </m.div>
          <Row className="justify-center">
            <Col
              lg={10}
              className="flex flex-row items-center justify-center text-center mt-[7.5rem]"
            >
              <span className="col-auto w-[40px] h-[1px] bg-darkgray opacity-30"></span>
              <div className="font-serif font-medium text-lg text-darkgray px-[20px] md:text-xmd sm:leading-[22px]">
              With 15 years of family legacy, we build secure, dream fences for you.
              </div>
              <span className="col-auto w-[40px] h-[1px] bg-darkgray opacity-30"></span>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Footer Start */}
      <FooterStyle09 theme="dark" className="text-[#7F8082] bg-darkgray" />
      {/* Footer End */}
    </div>
  );
};

export default FencingPage;
