const HeaderData = [
    {
        title: 'Home',
        link: '/'
      
    },
    {
        title: 'About',
        link: '#about'
    },
    {
        title: 'Services',
        link: '#services'
    },
    {
        title: 'Contact',
        link: '#contact'
    },
]

export default HeaderData