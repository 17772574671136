const ClientData01 = [
    {
        img: 'https://via.placeholder.com/225x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/225x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/225x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/225x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/225x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/225x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/225x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/225x110',
        target: "_self",
        link: '#'
    },
]

const ClientData02 = [
    {
        img: '/assets/img/behr.png',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/sherwin.png',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/benjamin.png',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/ppg.png',
        target: "_self",
        link: '#'
    }
]

const ClientData03 = [
    {
        img: 'https://via.placeholder.com/400x160',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/400x160',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/400x160',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/400x160',
        target: "_self",
        link: '#'
    }
]

const ClientData04 = [
    {
        img: 'https://via.placeholder.com/120x60',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/120x60',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/120x60',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/120x60',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/120x60',
        target: "_self",
        link: '#'
    }
]


const ClientData05 = [
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    }
]

const ClientData06 = [
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
]

const ClientData07 = [
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    },
    {
        img: 'https://via.placeholder.com/232x110',
        target: "_self",
        link: '#'
    }
]

const ClientData08 = [
    {
        img: "https://via.placeholder.com/80x92"
    },
    {
        img: "https://via.placeholder.com/80x92"
    },
    {
        img: "https://via.placeholder.com/80x92"
    },
    {
        img: "https://via.placeholder.com/80x92"
    }
]


export { ClientData01, ClientData02, ClientData03, ClientData04, ClientData05, ClientData06, ClientData07, ClientData08 };